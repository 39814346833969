import React from 'react';

export const webPagesConfigs = [
  {
    path: '/web/openhours',
    component: React.lazy(() => import('./Openhours/OpenHours')),
  },
  {
    path: '/web/banners',
    component: React.lazy(() => import('./Banners/Banners')),
  },
  {
    path: '/web/content',
    component: React.lazy(() => import('./Content/index')),
  }
];
