import React from 'react';


export const pimPagesConfigs = [
  {
    path: '/pim/category',
    component: React.lazy(() => import('./Category')),
  },
  {
    path: '/pim/import',
    component: React.lazy(() => import('./Import')),
  },
  {
    path: '/pim/imported',
    component: React.lazy(() => import('./Imported')),
  },
  {
    path: '/pim/stage',
    component: React.lazy(() => import('./Stage')),
  },
  {
    path: '/pim/products',
    component: React.lazy(() => import('./Products')),
  },
  {
    path: '/pim/imageSelect',
    component: React.lazy(() => import('./Products/imageSelect')),
  },
  {
    path: '/pim/typeSelect',
    component: React.lazy(() => import('./Products/typeSelect')),
  },
  {
    path: '/pim/productTypes',
    component: React.lazy(() => import('./ProductTypes')),
  },
  {
    path: '/pim/categoryRef',
    component: React.lazy(() => import('./CategoryRefs')),
  },
  {
    path: '/pim/vehicles',
    component: React.lazy(() => import('./Pages/Vehicles')),
  },
  {
    path: '/pim/brand',
    component: React.lazy(() => import('./Brand')),
  },
  {
    path: '/pim/attributes',
    component: React.lazy(() => import('./Attribute')),
  },
  {
    path: '/pim/attributesConfiguration',
    component: React.lazy(() => import('./AttributeConfiguration')),
  },
];
