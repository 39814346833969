import React from 'react';

export const articlePagesConfigs = [
  {
    path: '/articles/article',
    component: React.lazy(() => import('./Article')),
  },
  {
    path: '/articles/prices',
    component: React.lazy(() => import('./Prices')),
  }
];
