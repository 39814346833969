import { authRouteConfig } from "./auth";
import { initialUrl } from "shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import { errorPagesConfigs } from "./errorPages";
import { pimPagesConfigs } from "./pim";
import { warehousePagesConfigs } from "./warehouse";
import { articlePagesConfigs } from "./article";
import { orderPagesConfigs } from "./order";
import { webPagesConfigs } from "./web";
import { statisticsPagesConfigs } from "./statistics";
import { storePagesConfig } from './store';
import { purchaseConfigs } from "./purchase";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [...articlePagesConfigs, ...warehousePagesConfigs, ...pimPagesConfigs, ...orderPagesConfigs, ...webPagesConfigs, ...statisticsPagesConfigs, ...storePagesConfig, ...pimPagesConfigs, ...purchaseConfigs],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

const statusCodes = ["CREATED", "SHORTAGE", "DRAFT", "SENT", "RECEIVED", "WAREHOUSING", "WAITING", "SHIPPING", "PARKED", "NEED_HANDLING", "INBOUND", "INBOUNDING", "COMPLETE"];

const Status = ["NEW","CURRENT","OUTGOING","REPLACED","DISCONTINUED","INVALID","UNKNOWN"];

export { authorizedStructure, unAuthorizedStructure, anonymousStructure, statusCodes, Status };
