import React, { ReactNode } from "react";
import configureStore, { history } from "../../../redux/store";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

const store = configureStore();

interface AppReduxProviderProps {
  children: ReactNode;
}

const AppReduxProvider: React.FC<AppReduxProviderProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  );
};

export default AppReduxProvider;
