import React from 'react';

export const purchaseConfigs = [
  {
    path: '/purchases/purchaseOrder',
    component: React.lazy(() => import('./Purchase/index')),
  },
  {
    path: '/purchases/reconciliation',
    component: React.lazy(() => import('./Reconciliation/index')),
  },
  {
    path: '/purchase/suppliers',
    component: React.lazy(() => import('./Suppliers/index')),
  },
];
