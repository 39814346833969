import React from "react";
import { Box } from "@mui/material";

interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        padding: 2.5,
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          height: { xs: 40, sm: 45 },
        },
      }}
      className="app-logo"
    >
      {/*<Logo fill={theme.palette.primary.main} />*/}
      <img src={"/logo70.png"} alt=""/>
      <Box
        sx={{
          mt: 1,
          "& svg": {
            height: { xs: 25, sm: 30 },
          },
        }}
      >
        JINOTECO
          {/*<LogoText fill={alpha(theme.palette.text.primary, 0.8)} />*/}
      </Box>
    </Box>
  );
};

export default AppLogo;
