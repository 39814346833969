import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('162ac4b86570d46c0702c53006a929b3Tz02NDg3MixFPTE3MTM2MDE4NzkwNzIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
