import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AuthRoutes from "@crema/utility/AuthRoutes";
import AppContextProvider from "@crema/utility/AppContextProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppLayout from "@crema/core/AppLayout";
import AppReduxProvider from "./@crema/utility/AppReduxProvider";
import JWTAuthProvider from "./@crema/services/auth/jwt-auth/JWTAuthProvider";

const App = () => (
  <AppContextProvider>
    <AppReduxProvider>
      <AppThemeProvider>
        <AppStyleProvider>
          <AppLocaleProvider>
            <JWTAuthProvider>
              <AuthRoutes>
                <CssBaseline />
                <AppLayout />
              </AuthRoutes>
            </JWTAuthProvider>
          </AppLocaleProvider>
        </AppStyleProvider>
      </AppThemeProvider>
    </AppReduxProvider>
  </AppContextProvider>
);

export default App;
