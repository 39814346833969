import React from 'react';

export const warehousePagesConfigs = [
  {
    path: '/warehouse/locations',
    component: React.lazy(() => import('./Locations')),
  },
  {
    path: '/warehouse/stock',
    component: React.lazy(() => import('./Stock')),
  },
  {
    path: '/warehouse/vehicles',
    component: React.lazy(() => import('./Pages/Vehicles')),
  },
  {
    path: '/warehouse/usedVehicles',
    component: React.lazy(() => import('./Pages/StockUsedVehiclePage')),
  },
  {
    path: '/warehouse/transactions',
    component: React.lazy(() => import('./Transactions')),
  },
  {
    path: '/warehouse/stocktake',
    component: React.lazy(() => import('./stocktake')),
  },
  {
    path: '/warehouse/start',
    component: React.lazy(() => import('./Start')),
  },
];
