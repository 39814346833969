import React, { ReactNode } from "react";
import Button from "@mui/material/Button";
import IntlMessages from "../../utility/IntlMessages";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { Fonts } from "../../../shared/constants/AppEnums";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AppConfirmDialogProps {
  dialogTitle: string | ReactNode;
  open: boolean;
  onDeny: (isOpen: boolean) => void;
  title: string | ReactNode;
  onConfirm: () => void;
  selectedStatus?: any;
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  open,
  onDeny,
  onConfirm,
  title,
  dialogTitle,
  selectedStatus,
}) => {

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={() => onDeny(false)}
    >
      <DialogTitle
        sx={{
          mb: 3,
          fontWeight: Fonts.SEMI_BOLD,
        }}
        id="alert-dialog-title"
      >
        {dialogTitle}
        {!selectedStatus ? null : selectedStatus}?
      </DialogTitle>

      <DialogContent
        sx={{ color: "text.secondary", fontSize: 14 }}
        id="alert-dialog-description"
      >
        {title}
      </DialogContent>
      <DialogActions
        sx={{
          pb: 5,
          px: 6,
        }}
      >
        <Button
          variant="outlined"
          disabled={onConfirm === null}
          sx={{
            fontWeight: Fonts.MEDIUM,
          }}
          onClick={onConfirm}
          color="primary"
          autoFocus
        >
          {title ? <IntlMessages id="common.yes" /> : "OK"}
        </Button>
        <Button
          variant="outlined"
          sx={{
            fontWeight: Fonts.MEDIUM,
          }}
          onClick={() => onDeny(false)}
          color="secondary"
        >
          {title ? <IntlMessages id="common.no" /> : "Avbryt"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppConfirmDialog;
