import React from 'react';

export const orderPagesConfigs = [
  {
    path: '/orders/delivery',
    component: React.lazy(() => import('./Order')),
  },
  {
    path: '/orders/pos',
    component: React.lazy(() => import('./Pos')),
  },
  {
    path: '/orders/customerOrders',
    component: React.lazy(() => import('./CustomersOrders')),
  },
  {
    path: '/orders/offers',
    component: React.lazy(() => import('./Offer')),
  },
  {
    path: '/orders/workshop',
    component: React.lazy(() => import('./Workshop')),
  },
  {
    path: '/orders/OrderDelivery',
    component: React.lazy(() => import('./OrderDelivery')),
  },
  {
    path: '/orders/pack',
    component: React.lazy(() => import('./Pack')),
  }
];
