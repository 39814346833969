export const checkPermission = (routeRole: any, userRole: any, type: any) => {
  // userRole = JSON.parse(localStorage.getItem("user")).user?.role;
  if (((routeRole || userRole) && type === "item") || ((routeRole && userRole) && type === "collapse")) {
    return true;
  }
  // console.warn(`checkPermission userRole: ${JSON.stringify(userRole)} routeRole: ${routeRole}`);
  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.indexOf(routeRole) >= 0;
  }
  if (routeRole && routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeRole && routeRole.indexOf(userRole) >= 0;
};
